import React, { useEffect, useState } from 'react'

// import { useRecusaciones } from '../hooks/useRecusaciones'
import Table from '../components/Table'
import Modal from '../components/Modal'

const RecusacionesPage = () => {
    const [filterData, setFilterData] = useState([])
    const [selectedRecusacion, setSelectedRecusacion] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formdata, setFormdata] = useState({
        nombres: '',
        apellidos: '',
        expediente: '',
        resultado: ''
    })
    // const { recusaciones } = useRecusaciones();

    // useEffect(() => {
    //     setFilterData(recusaciones)
    // }, [recusaciones])

    const columns = [
        {
            header: 'Nombres',
            accessorKey: 'nombres'
        },
        {
            header: 'Apellidos',
            accessorKey: 'apellidos'
        },
        {
            header: 'Expediente',
            accessorKey: 'expediente'
        },
        {
            header: 'Descripción',
            accessorKey: 'descripcion'
        },
        {
            header: 'Acciones',
            cell: ({ cell }) => {
                const id = cell.row.original.idrecusaciones
                return <div className='flex items-center'>
                    <button
                        className="bg-primary hover:bg-primary/90 text-xs text-white/90 px-4 py-1 rounded-md"
                        onClick={() => { openModal(id) }}
                    >
                        Detalles
                    </button>
                </div>
            }
        }
    ]

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormdata((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        // const filteredItems = recusaciones.filter((item) => {
        //     return Object.entries(formdata).every(([key, value]) => {
        //         if (value === '') {
        //             return true;
        //         }
        //         return item[key].includes(value);
        //     });
        // });
        // setFilterData(filteredItems);
    }

    const openModal = (id) => {
        setIsModalOpen(true);
        setSelectedRecusacion(id)
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="w-3/4 overflow-y-scroll">
            <div className="w-[90%] mx-auto my-20">
                <h3 className='text-2xl text-zinc-500 uppercase font-bold'>Recusaciones por árbitro</h3>
                <form onSubmit={handleSubmit}>
                    <div className='grid grid-cols-2 gap-4 mt-3'>
                        <input
                            type="text"
                            className='border border-secondary rounded-md p-2 text-sm text-zinc-700 bg-secondary/10 w-full'
                            placeholder='Buscar por nombres'
                            name='nombres'
                            value={formdata.nombres}
                            onChange={handleInputChange}
                        />
                        <input
                            type="text"
                            className='border border-secondary rounded-md p-2 text-sm text-zinc-700 bg-secondary/10 w-full'
                            placeholder='Buscar por apellidos'
                            name='apellidos'
                            value={formdata.apellidos}
                            onChange={handleInputChange}
                        />
                        <input
                            type="text"
                            className='border border-secondary rounded-md p-2 text-sm text-zinc-700 bg-secondary/10 w-full'
                            placeholder='Buscar por número de expediente'
                            name='expediente'
                            value={formdata.expediente}
                            onChange={handleInputChange}
                        />
                        <select
                            className='border border-secondary rounded-md p-2 text-sm text-zinc-700 bg-secondary/10 w-full'
                            value={formdata.resultado}
                            name='resultado'
                            onChange={handleInputChange}
                        >
                            <option hidden value='' className='text-zinc-400'>Buscar por resultado</option>
                            <option value='Fundada'>Fundada</option>
                            <option value='Infundada'>Infundada</option>
                            <option value='Improcedente'>Improcedente</option>
                            <option value='Renuncia'>Renuncia</option>
                        </select>
                        <button
                            type='submit'
                            className='bg-primary hover:bg-primary/90 text-white rounded-md p-2 text-sm'
                        >
                            Buscar
                        </button>
                    </div>
                </form>
                <div className='mt-3'>
                    <Table columns={columns} data={filterData} />
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={closeModal} title={'Recusaciones por árbitro'} iddata={selectedRecusacion} />
        </div>
    )
}

export default RecusacionesPage