import React from 'react'
import ButtonMenu from './ButtonMenu';

const Menu = () => {

    return (
        <div className="w-1/4 h-full bg-secondary/20 flex flex-col justify-center items-start p-10 gap-3">
            <img className='w-full' src='https://www.arbitrajeccia.com.pe/assets/logo_arbitraje.png' alt='Centro de arbitraje CCIA' />
            <p className='text-sm text-zinc-600'>Seleccione una opción.</p>
            <div className='flex flex-wrap justify-center gap-3'>
                <ButtonMenu name='GPS arbitral' url='/gps' />
                <ButtonMenu name='Estadísticas' url='/transparencia' />
                <ButtonMenu name='Recusaciones por árbitro' url='/recusaciones' />
            </div>
        </div>
    )
}

export default Menu