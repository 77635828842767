import React from 'react'
import { Link, useRoute } from "wouter";

const ButtonMenu = (props) => {

    const [isActive] = useRoute(props.url);

    return (
        <Link to={props.url}>
            <div className={`w-full flex items-center justify-between p-3 cursor-pointer hover:bg-zinc-500 rounded-md  hover:text-zinc-200 transition-colors duration-200 ${isActive ? "bg-zinc-500 text-zinc-200" : "bg-zinc-300 text-zinc-500"}`}>
                <label className='text-sm uppercase'>{props.name}</label>
                <svg xmlns="http://www.w3.org/2000/svg" className='w-6' viewBox="0 0 512 512"><path d="M160 115.4L180.7 96 352 256 180.7 416 160 396.7 310.5 256z" fill="currentColor" /></svg>
            </div>
        </Link>
    )
}

export default ButtonMenu