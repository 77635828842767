import React, { useState } from 'react'
import Table from '../components/Table'

import { useRecusaciones } from '../hooks/useRecusaciones'

const GpsPage = () => {
    const [filterData, setFilterData] = useState([])
    const [formdata, setFormdata] = useState({
        expediente: '',
        demandante: '',
        demandado: '',
        presidente: '',
        fecha_ingreso: '',
        fecha_final: ''
    })

    const { laudos, arbitros } = useRecusaciones();

    const columns = [
        {
            header: 'Tipo',
            accessorKey: 'numero_resolucion'
        },
        {
            header: 'Partes (Demandante)',
            accessorKey: 'demandante'
        },
        {
            header: 'Partes (Demandado)',
            accessorKey: 'demandado'
        },
        {
            header: 'Decisión Compl.',
            cell: ({ cell }) => {
                const document = cell.row.original.decision_digital
                if (document !== '' && document !== null) {
                    return <div className='flex items-center'>
                        <a
                            href={`https://arbitrajeccia.com.pe/sistema//public/archivos/resoluciones/${document}`}
                            target='_blank'
                            rel='noreferrer'
                            className="bg-primary hover:bg-primary/90 text-xs text-white/90 px-4 py-1 rounded-md"
                        >
                            Ver
                        </a>
                    </div>
                } else {
                    return <p className='text-xs text-zinc-500'>No disponible</p>
                }
            }
        },
        {
            header: 'Laudo',
            cell: ({ cell }) => {
                const document = cell.row.original.resolucion_digital
                if (document !== '' && document !== null) {
                    return <div className='flex items-center'>
                        <a
                            href={`https://arbitrajeccia.com.pe/sistema//public/archivos/resoluciones/${document}`}
                            target='_blank'
                            rel='noreferrer'
                            className="bg-primary hover:bg-primary/90 text-xs text-white/90 px-4 py-1 rounded-md"
                        >
                            Ver
                        </a>
                    </div>
                } else {
                    return <p className='text-xs text-zinc-500'>No disponible</p>
                }
            }
        }
    ]

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormdata((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        const filteredItems = laudos.filter((item) => {
            return Object.entries(formdata).every(([key, value]) => {
                if (value === '') {
                    return true;
                }
                return item[key].includes(value);
            });
        });
        setFilterData(filteredItems);
    }

    return (
        <div className="w-3/4 overflow-y-scroll">
            <div className="w-[90%] mx-auto my-20">
                <h3 className='text-2xl text-zinc-500 uppercase font-bold'>GPS Arbitral</h3>
                <form onSubmit={handleSubmit}>
                    <div className='grid grid-cols-2 gap-4 mt-3'>
                        <input
                            type="text"
                            className='border border-secondary rounded-md p-2 text-sm text-zinc-700 bg-secondary/10 w-full'
                            placeholder='001-2000-TA-CCIA'
                            name='expediente'
                            value={formdata.expediente}
                            onChange={handleInputChange}
                        />
                        <input
                            type="text"
                            className='border border-secondary rounded-md p-2 text-sm text-zinc-700 bg-secondary/10 w-full'
                            placeholder='Partes (demandante)'
                            name='demandante'
                            value={formdata.demandante}
                            onChange={handleInputChange}
                        />
                        <input
                            type="text"
                            className='border border-secondary rounded-md p-2 text-sm text-zinc-700 bg-secondary/10 w-full'
                            placeholder='Partes (demandado)'
                            name='demandado'
                            value={formdata.demandado}
                            onChange={handleInputChange}
                        />
                        <select
                            className='border border-secondary rounded-md p-2 text-sm text-zinc-700 bg-secondary/10 w-full'
                            value={formdata.presidente}
                            name='presidente'
                            onChange={handleInputChange}
                        >
                            <option hidden value='' className='text-zinc-400'>Selecciona árbitro</option>
                            {
                                arbitros.map((arbitro) => (
                                    <option key={arbitro.idarbitros} value={arbitro.idarbitros}>{arbitro.nombres}</option>
                                ))
                            }
                        </select>
                        <input
                            type="text"
                            className='border border-secondary rounded-md p-2 text-sm text-zinc-700 bg-secondary/10 w-full'
                            placeholder='Fecha de ingreso (aaaa-mm-dd)'
                            name='fecha_ingreso'
                            value={formdata.fecha_ingreso}
                            onChange={handleInputChange}
                        />
                        <input
                            type="text"
                            className='border border-secondary rounded-md p-2 text-sm text-zinc-700 bg-secondary/10 w-full'
                            placeholder='Fecha de finalización (aaaa-mm-dd)'
                            name='fecha_final'
                            value={formdata.fecha_final}
                            onChange={handleInputChange}
                        />
                        <button
                            type='submit'
                            className='bg-primary hover:bg-primary/90 text-white rounded-md p-2 text-sm'
                        >
                            Buscar
                        </button>
                    </div>
                </form>
                <div className='mt-3'>
                    <Table columns={columns} data={filterData} />
                </div>
            </div>
        </div>
    )
}

export default GpsPage