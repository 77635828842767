import React, { useEffect, useState } from 'react';

const TransparenciaPage = () => {
    const [year, setYear] = useState('2023');
    const [data, setData] = useState([]);

    useEffect(() => {
        fetch('https://www.arbitrajeccia.com.pe/sistema/resttransparencia/' + year)
            .then(response => response.json())
            .then(data => setData(data))
    }, [year])

    return (
        <div className="w-3/4 overflow-y-scroll">
            <div className="w-[90%] mx-auto my-20">
                <form className='flex gap-4 items-center mb-5'>
                    <h3 className='text-2xl text-zinc-500 uppercase font-bold'>Estadísticas</h3>
                    <select
                        className='border border-secondary rounded-md p-2 text-sm text-zinc-700 bg-secondary/20 min-w-[100px] hidden'
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                    >
                        <option value='2023'>2023</option>
                        <option value='2022'>2022</option>
                        <option value='2021'>2021</option>
                        <option value='2020'>2020</option>
                        <option value='2019'>2019</option>
                        <option value='2018'>2018</option>
                        <option value='2017'>2017</option>
                        <option value='2016'>2016</option>
                        <option value='2015'>2015</option>
                    </select>
                </form>
                {/* <h3 className='text-lg text-zinc-400 uppercase font-bold my-4'>Casos nuevos</h3>
                <div className="grid grid-cols-4 gap-4">
                    <dl className="rounded-xl bg-blue-100 text-blue-800 py-3 px-5">
                        <dt className="text-2xl font-bold">{data.procesos}</dt>
                        <dd className="text-sm opacity-80">Nuevos procesos</dd>
                    </dl>
                </div> */}
                <h3 className='text-lg text-zinc-400 uppercase font-bold my-4'>Arbitrajes con el estado</h3>
                <div className="grid grid-cols-4 gap-4">
                    <dl className="rounded-xl bg-blue-100 text-blue-800 py-3 px-5">
                        <dt className="text-2xl font-bold">{data.conestado} %</dt>
                        <dd className="text-sm opacity-80">Participación con el estado</dd>
                    </dl>
                    <dl className="rounded-xl bg-blue-100 text-blue-800 py-3 px-5">
                        <dt className="text-2xl font-bold">{data.comercial} %</dt>
                        <dd className="text-sm opacity-80">Participación comercial</dd>
                    </dl>
                    <dl className="rounded-xl bg-blue-100 text-blue-800 py-3 px-5">
                        <dt className="text-2xl font-bold">{data.otros} %</dt>
                        <dd className="text-sm opacity-80">Participación otros</dd>
                    </dl>
                </div>
                {/* <h3 className='text-lg text-zinc-400 uppercase font-bold my-4'>Cuantías en controversia (S/)</h3>
                <div className="grid grid-cols-4 gap-4">
                    <dl className="rounded-xl bg-blue-100 text-blue-800 py-3 px-5">
                        <dt className="text-2xl font-bold">{data.cuantiamax}</dt>
                        <dd className="text-sm opacity-80">Máxima cuantía en controversias</dd>
                    </dl>
                    <dl className="rounded-xl bg-blue-100 text-blue-800 py-3 px-5">
                        <dt className="text-2xl font-bold">{data.cuantiamin}</dt>
                        <dd className="text-sm opacity-80">Mínimo cuantía en controversias</dd>
                    </dl>
                </div> */}
                <h3 className='text-lg text-zinc-400 uppercase font-bold my-4'>Tipos de controversia</h3>
                <div className="grid grid-cols-4 gap-4">
                    {
                        data.tipocontroversia?.map((tipo, index) => (
                            <dl key={index} className="rounded-xl bg-blue-100 text-blue-800 py-3 px-5">
                                <dt className="text-2xl font-bold">{tipo.porcentaje} %</dt>
                                <dd className="text-sm opacity-80">{tipo.option_mod}</dd>
                            </dl>
                        ))
                    }
                </div>
                <h3 className='text-lg text-zinc-400 uppercase font-bold my-4'>Recusaciones de árbitros</h3>
                <div className="grid grid-cols-4 gap-4">
                    <dl className="rounded-xl bg-blue-100 text-blue-800 py-3 px-5">
                        <dt className="text-2xl font-bold">-</dt>
                        <dd className="text-sm opacity-80">Recusación de Árbitros</dd>
                    </dl>
                    <dl className="rounded-xl bg-blue-100 text-blue-800 py-3 px-5">
                        <dt className="text-2xl font-bold">-</dt>
                        <dd className="text-sm opacity-80">Fundadas</dd>
                    </dl>
                    <dl className="rounded-xl bg-blue-100 text-blue-800 py-3 px-5">
                        <dt className="text-2xl font-bold">-</dt>
                        <dd className="text-sm opacity-80">Infundadas</dd>
                    </dl>
                    <dl className="rounded-xl bg-blue-100 text-blue-800 py-3 px-5">
                        <dt className="text-2xl font-bold">-</dt>
                        <dd className="text-sm opacity-80">Improcedentes</dd>
                    </dl>
                    <dl className="rounded-xl bg-blue-100 text-blue-800 py-3 px-5">
                        <dt className="text-2xl font-bold">-</dt>
                        <dd className="text-sm opacity-80">Renuncias</dd>
                    </dl>
                </div>
                <h3 className='text-lg text-zinc-400 uppercase font-bold my-4'>Cantidad de árbitros</h3>
                <div className="grid grid-cols-4 gap-4">
                    <dl className="rounded-xl bg-blue-100 text-blue-800 py-3 px-5">
                        <dt className="text-2xl font-bold">{data.arbitros}</dt>
                        <dd className="text-sm opacity-80">Total de árbitros nómina</dd>
                    </dl>
                    <dl className="rounded-xl bg-blue-100 text-blue-800 py-3 px-5">
                        <dt className="text-2xl font-bold">{data.masculinos} %</dt>
                        <dd className="text-sm opacity-80">Hombre</dd>
                    </dl>
                    <dl className="rounded-xl bg-blue-100 text-blue-800 py-3 px-5">
                        <dt className="text-2xl font-bold">{data.femeninos} %</dt>
                        <dd className="text-sm opacity-80">Mujer</dd>
                    </dl>
                </div>
            </div>
        </div>
    )
}

export default TransparenciaPage