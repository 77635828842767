import React, { useEffect, useState } from 'react';

const Modal = ({ isOpen, onClose, title, iddata }) => {
    const [data, setData] = useState([])

    useEffect(() => {
        fetch(`https://arbitrajeccia.com.pe/sistema/restrecusaciones/${iddata}`)
            .then(response => response.json())
            .then(data => setData(data))
    }, [iddata])

    if (!isOpen) {
        return null;
    }

    return (
        <div className="fixed bg-zinc-700/50 inset-0 flex items-center justify-center z-[100]">
            <div className="relative bg-white rounded-lg p-6 shadow-lg w-10/12 h-[90vh]">
                <button
                    className="absolute top-0 right-0 mt-4 mr-4 text-gray-500 hover:text-gray-700"
                    onClick={onClose}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className='w-6' viewBox="0 0 512 512"><path fill="currentColor" d="m289.94 256l95-95A24 24 0 0 0 351 127l-95 95l-95-95a24 24 0 0 0-34 34l95 95l-95 95a24 24 0 1 0 34 34l95-95l95 95a24 24 0 0 0 34-34Z" /></svg>
                </button>
                <h2 className="text-lg text-black/60 font-semibold uppercase mb-4">{title}</h2>
                <div>
                    <h2 className='text-base text-zinc-400 uppercase font-bold mt-7 mb-2 tracking-wide'>Información</h2>
                    <div className='grid grid-cols-3 gap-3'>
                        <div className='flex flex-col gap-1'>
                            <label className='text-xs text-zinc-400 uppercase tracking-wider'>Número Expediente</label>
                            <label className='border border-secondary rounded-md p-2 text-xs text-zinc-600 bg-secondary/20 w-full'>
                                {data.expediente}
                            </label>
                        </div>
                        <div className='flex flex-col gap-1'>
                            <label className='text-xs text-zinc-400 uppercase tracking-wider'>Fecha Solicitud</label>
                            <label className='border border-secondary rounded-md p-2 text-xs text-zinc-600 bg-secondary/20 w-full'>
                                {data.fecha_hora}
                            </label>
                        </div>
                        <div className='flex flex-col gap-1'>
                            <label className='text-xs text-zinc-400 uppercase tracking-wider'>Resultado</label>
                            <label className='border border-secondary rounded-md p-2 text-xs text-zinc-600 bg-secondary/20 w-full'>
                                {data.descripcion}
                            </label>
                        </div>
                    </div>
                    <h2 className='text-base text-zinc-400 uppercase font-bold mt-7 mb-2 tracking-wide'>Partes</h2>
                    <div className='grid grid-cols-2 gap-3'>
                        <div className='flex flex-col gap-1'>
                            <label className='text-xs text-zinc-400 uppercase tracking-wider'>Demandante</label>
                            <label className='border border-secondary rounded-md p-2 text-xs text-zinc-600 bg-secondary/20 w-full'>
                                {data.demandante}
                            </label>
                        </div>
                        <div className='flex flex-col gap-1'>
                            <label className='text-xs text-zinc-400 uppercase tracking-wider'>Demandado</label>
                            <label className='border border-secondary rounded-md p-2 text-xs text-zinc-600 bg-secondary/20 w-full'>
                                {data.demandado}
                            </label>
                        </div>
                    </div>
                    <h2 className='text-base text-zinc-400 uppercase font-bold mt-7 mb-2 tracking-wide'>Persona recusada</h2>
                    <div className='grid grid-cols-2 gap-3'>
                        <div className='flex flex-col gap-1'>
                            <label className='text-xs text-zinc-400 uppercase tracking-wider'>Árbitro</label>
                            <label className='border border-secondary rounded-md p-2 text-xs text-zinc-600 bg-secondary/20 w-full'>
                                {data.apellidos}, {data.nombres}
                            </label>
                        </div>
                    </div>
                    <h2 className='text-base text-zinc-400 uppercase font-bold mt-7 mb-2 tracking-wide'>Documentación</h2>
                    <div className='grid grid-cols-3 gap-3'>
                        <a href={`${data.archivo}`} className='border-2 border-primary rounded-md p-2 text-xs uppercase text-zinc-700 w-full tracking-wider hover:bg-primary hover:text-zinc-100 transition-colors duration-300'>
                            Ver documento
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal