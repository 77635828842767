import { useState, useEffect, useCallback } from 'react'

export function useRecusaciones() {

    const [recusaciones, setRecusaciones] = useState([])
    const [laudos, setLaudos] = useState([])
    const [arbitros, setArbitros] = useState([])

    const getAllRecusaciones = useCallback(() => {
        fetch(`https://arbitrajeccia.com.pe/sistema/restrecusaciones`)
            .then(response => response.json())
            .then(data => setRecusaciones(data))

    }, [])

    useEffect(() => {
        getAllRecusaciones()
    }, [getAllRecusaciones])

    const getAllLaudos = useCallback(() => {
        fetch(`https://arbitrajeccia.com.pe/sistema/restlaudos`)
            .then(response => response.json())
            .then(data => setLaudos(data))

    }, [])

    useEffect(() => {
        getAllLaudos()
    }, [getAllLaudos])

    const getAllArbitros = useCallback(() => {
        fetch(`https://arbitrajeccia.com.pe/sistema/restarbitrators`)
            .then(response => response.json())
            .then(data => setArbitros(data.data))

    }, [])

    useEffect(() => {
        getAllArbitros()
    }, [getAllArbitros])

    return { 
        recusaciones,
        laudos,
        arbitros
    }

}