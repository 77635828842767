import React from 'react';
import { Route, Router } from "wouter";
import './App.css';
import Menu from './components/Menu';
import TransparenciaPage from './pages/TransparenciaPage';
import RecusacionesPage from './pages/RecusacionesPage';
import GpsPage from './pages/GpsPage';

function App() {

  return (
    <div className="w-full h-screen flex">
      <Menu />
      <Router>
        <Route path="/">
          <TransparenciaPage />
        </Route>
        <Route path="/gps">
          <GpsPage />
        </Route>
        <Route path="/transparencia">
          <TransparenciaPage />
        </Route>
        <Route path="/recusaciones">
          <RecusacionesPage />
        </Route>
      </Router>
    </div>
  );
}

export default App;
